import React, { useEffect, useState } from "react";
import { enquireScreen } from "enquire-js";
import {
  Registration,
  Admission,
  Courses,
  AboutUs,
  Placement,
  Testimonials
} from "./index";
import FooterComp from "../components/FooterComp";
import Benefit from "../components/Benefit";
const components = {
  registration: Registration,

  admission: Admission,
  courses: Courses,
  aboutUs: AboutUs,
  placement: Placement,
  testimonials: Testimonials,
  footer: FooterComp,
  benefit: Benefit
};

const Test = ({
  isRedirecting,
  component,
  data,
  layout,
  component_id,
  college_id
}) => {
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    enquireScreen(status => {
      setIsMobile(status);
    });
    return () => {
      //cleanup
    };
  }, [isMobile]);

  const componentName =
    isMobile && component.mobile
      ? component.mobile.name
      : component.desktop.name;

  const Component = components[componentName];

  return components[componentName] ? (
    <Component
      data={data}
      layout={layout}
      isMobile={isMobile}
      component_id={component_id}
      college_id={college_id}
      isRedirecting={isRedirecting}
    />
  ) : null;
};
export default Test;
