import React, { useEffect, useState } from "react";
import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor";
import axios from "axios";
import { Test } from "../components";
import { Row, Col, Result, Spin } from "antd";
import { Helmet } from "react-helmet";
import { enquireScreen } from "enquire-js";
import Header from "../components/Header";
import "../less/antMotionStyle.less";
configureAnchors({ offset: -62, scrollDuration: 1000 });

const HomePageComponent = props => {
  const {
    location: { pathname }
  } = props;
  const [isMobile, setIsMobile] = useState();
  const [redirecting, setRedirecting] = useState(false);
  const [collegeData, setCollegeData] = useState({});
  const [headerData, setHeaderData] = useState(null);
  const [errorStatus, setErrorStatus] = useState(false);
  const [errorSubtitle, setErrorSubtitle] = useState("");
  const { id, title, description, logo_url, data } = collegeData;
  const isRedirecting = value => {
    setRedirecting(value);
  };

  const bucket =
    process.env.NODE_ENV === "production" ? "ezap-prod" : "ezap-dev";

  useEffect(() => {
    enquireScreen(status => setIsMobile(status));

    const fetchData = async () => {
      try {
        const dataPath = `https://storage.googleapis.com/${bucket}/microsite${pathname}/data.json`;
        const headerPath = `https://storage.googleapis.com/${bucket}/microsite${pathname}/header.json`;

        const collegeDataRequest = axios.get(dataPath);
        const headerDataRequest = axios.get(headerPath);
        const [collegeData, headerData] = await Promise.all([
          collegeDataRequest,
          headerDataRequest
        ]);
        setCollegeData(collegeData.data || {});
        setHeaderData(headerData.data || {});
      } catch (error) {
        setErrorSubtitle(
          error.message || "Something went wrong. Please try again later"
        );
        setErrorStatus(true);
      }
    };
    fetchData();
  }, [pathname, bucket]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      {errorStatus ? (
        <Result status="500" title="Error!!!" subTitle={errorSubtitle} />
      ) : headerData && data && data.length ? (
        redirecting ? (
          <Result
            title="Redirecting to common application form"
            extra={<Spin />}
          />
        ) : (
          <React.Fragment>
            <Header
              id="header"
              key="header"
              dataSource={headerData}
              logo_url={logo_url}
              isMobile={isMobile}
            />

            {data.map(item => (
              <ScrollableAnchor key={item.id} id={item.id}>
                <Row
                  type="flex"
                  justify="center"
                  className={item.layout.backgroundColor}
                >
                  <Col
                    className={item.class}
                    {...item.layout.sectionGridConfig}
                    type={item.id === "admission" ? "" : "flex"}
                    align={item.id === "admission" ? "" : "middle"}
                  >
                    <Test
                      component={item.components}
                      data={item.data}
                      layout={item.layout}
                      component_id={item.id}
                      college_id={id}
                      isRedirecting={isRedirecting}
                    />
                  </Col>
                </Row>
              </ScrollableAnchor>
            ))}
          </React.Fragment>
        )
      ) : null}
    </React.Fragment>
  );
};
export default HomePageComponent;
