import React from "react";

const FooterComp = ({ data, layout }) => {
  const { values } = data;

  return (
    <>
      <div className="footer-curve" />
      <div
        style={{
          textAlign: "left",
          color: "white",
          fontFamily: "Muli",
          padding: "50px"
        }}
      >
        <img src={require("../images/ez-logo.svg")} alt="ez-logo" />
        {values.map((item, index) => {
          return (
            <p style={{ marginTop: "4rem" }} key={`footer-${index}`}>
              {item.description}
            </p>
          );
        })}
      </div>
    </>
  );
};
export default FooterComp;
