import React, { useState, useEffect } from "react";
import { Card, Row, Col, Divider } from "antd";
import Heading from "./Heading";
import { enquireScreen } from "enquire-js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const { Meta } = Card;

const Testimonials = ({ data, layout }) => {
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    enquireScreen(status => setIsMobile(status));
    return () => {
      //cleanup
    };
  }, [isMobile]);

  const { values } = data;
  const { headerColor } = layout;
  return (
    <React.Fragment>
      {data.heading && (
        <Heading
          title={data.heading.title}
          description={data.heading.description}
          theme={headerColor}
        />
      )}

      <Row
        gutter={[32, 32]}
        type="flex"
        justify="center"
        className="testimonial"
      >
        {values.map((testimonial, index) => {
          return (
            <Col
              xs={24}
              md={8}
              lg={8}
              type="flex"
              key={`testimonial-${index}`}
              align="middle"
            >
              <Card
                className="testimonial-carousel-row"
                cover={
                  <img alt={testimonial.name} src={testimonial.image_path} />
                }
              >
                <Meta title={testimonial.name} />
                <p
                  style={{
                    marginTop: "5px",
                    color: "#3c1a5b",
                    fontWeight: "500"
                  }}
                >
                  {testimonial.designation}
                </p>
                <Divider type="horizontal" />
                <p style={{ color: "#000" }}>{testimonial.comment}</p>
                <p
                  style={{
                    marginTop: "9px",
                    color: "#3c1a5b",
                    fontWeight: "500"
                  }}
                >
                  {testimonial.session}
                </p>
              </Card>
            </Col>
          );
        })}
      </Row>
    </React.Fragment>
  );
};

export default Testimonials;
