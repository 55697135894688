import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { UserContext } from "../context";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Checkbox,
  Button,
  notification
} from "antd";
const { Option } = Select;

const RegistrationForm = ({ isRedirecting, data, form, college_id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { image_path } = data;
  const { courseList, courseSelected, setCourseSelected } = useContext(
    UserContext
  );
  let { college_name } = useParams();

  const handleSubmit = async e => {
    e.preventDefault();
    const values = await form.validateFieldsAndScroll();
    try {
      setIsLoading(true);
      const {
        name,
        email,
        phone,
        interested_courses,
        has_agreed_to_terms
      } = values;
      const api =
        process.env.NODE_ENV === "production"
          ? "https://www.entrancezone.com/api/v1/microsite"
          : "https://beta.entrancezone.com/api/v1/microsite";
      const response = await axios.post(api, {
        name,
        email,
        phone,
        interested_courses,
        has_agreed_to_terms,
        college_id: college_id
      });
      notification.success({
        message: response.data.message
      });
      setIsLoading(false);
      setTimeout(() => {
        isRedirecting(false);
        window.open(`https://caf.entrancezone.com/${college_name}`);
      }, 3500);
      isRedirecting(true);
    } catch (error) {
      setIsLoading(false);
      notification.error({
        message: "Something went wrong"
      });
    }
  };
  useEffect(() => {
    if (courseSelected) {
      handleCourse();
    }
  }, [courseSelected]);
  const handleCourse = () => {
    const temp = form.getFieldValue("interested_courses");

    if (temp) {
      if (!temp.includes(courseSelected)) {
        form.setFieldsValue({
          interested_courses: [...temp, courseSelected]
        });
      }
    } else {
      form.setFieldsValue({
        interested_courses: [courseSelected]
      });
    }
  };
  const { getFieldDecorator } = form;
  const validateMobileNo = (rule, value, callback) => {
    if (isNaN(value)) {
      callback("!! Please enter 10 digit Mobile No. !!");
      return;
    }
    callback();
  };
  const checkCheckBox = (rule, value, callback) => {
    if (!value) {
      callback("Please agree the terms and conditions!");
    } else {
      callback();
    }
  };
  return (
    <Row
      className="register-image-header"
      style={{
        alignItems: "center",
        backgroundSize: "cover",
        backgroundImage: `url(${image_path})`
      }}
      type="flex"
    >
      <Col
        xs={24}
        md={12}
        lg={12}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start"
        }}
      >
        <div className="left-text-register">
          {data.values.map((item, index) => {
            return (
              <div
                style={{ marginBottom: "4rem" }}
                key={`registration-${index}`}
              >
                <h3 className="admission-heading">{item.title}</h3>
                <h3 className="admission-college">{item.college_location}</h3>
                <p className="admission-para">{item.description}</p>

                {item.accreditions
                  ? item.accreditions.map((data, index) => (
                      <img
                        key={index}
                        src={require(`../images/accreditions/${data.url}.png`)}
                        alt={data.title}
                        style={{
                          paddingRight: "2rem",
                          height: "6rem",
                          marginTop: "4rem"
                        }}
                      />
                    ))
                  : null}
              </div>
            );
          })}
        </div>
      </Col>
      <Col xs={24} md={12}>
        <div className="register-form-column">
          <div className="register-form">
            <Form
              onSubmit={handleSubmit}
              style={{ textAlign: "initial" }}
              hideRequiredMark
            >
              <div className="registration-ant-form">
                <Form.Item label="Name">
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: "Please enter your name",
                        whitespace: true
                      }
                    ]
                  })(
                    <Input
                      placeholder="Enter your name"
                      className="register-input"
                    />
                  )}
                </Form.Item>

                <Form.Item label="Email">
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        type: "email",
                        message: "Type Email in correct way"
                      },
                      {
                        required: true,
                        message: "Please enter you email"
                      }
                    ]
                  })(
                    <Input
                      placeholder="Enter your Email"
                      className="register-input"
                    />
                  )}
                </Form.Item>

                <Form.Item label="Contact">
                  {getFieldDecorator("phone", {
                    rules: [
                      {
                        required: true,
                        message: "Phone is required"
                      },
                      {
                        len: 10,
                        type: "string",
                        message: "Please enter 10 digit number"
                      },
                      {
                        validator: validateMobileNo
                      }
                    ]
                  })(
                    <Input
                      placeholder="Enter your phone number"
                      className="register-input"
                      style={{ width: "100%" }}
                      maxLength={10}
                    />
                  )}
                </Form.Item>
                <Form.Item label="Courses" style={{ maxWidth: "254px" }}>
                  {getFieldDecorator("interested_courses", {
                    rules: [
                      { required: true, message: "Please select a course" }
                    ]
                  })(
                    <Select
                      placeholder="Please select a course"
                      className="register-select"
                      showArrow={true}
                      mode="multiple"
                      onDeselect={item => {
                        if (courseSelected === item) {
                          setCourseSelected(null);
                        }
                      }}
                    >
                      {courseList &&
                        courseList.map(course => (
                          <Option
                            key={course.short_name}
                            value={course.short_name}
                          >
                            {course.short_name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("has_agreed_to_terms", {
                    valuePropName: "checked",
                    rules: [{ validator: checkCheckBox }]
                  })(
                    <Checkbox className="register-checkbox">
                      I agree to the
                      <a
                        href="https://www.entrancezone.com/engineering/privacy-policy/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        terms and conditions
                      </a>
                    </Checkbox>
                  )}
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  block
                  size="large"
                >
                  APPLY NOW
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Col>
    </Row>
  );
};

const WrappedRegistrationForm = Form.create({ name: "register" })(
  RegistrationForm
);

export default WrappedRegistrationForm;
