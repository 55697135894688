import React from "react";
import "./styles/App.css";
import "antd/dist/antd.css";
import HomePageComponent from "./pages/HomePageComponent";
import { UserProvider } from "./context";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
const App = () => {
  return (
    <div className="App">
      <UserProvider>
        <Router>
          <Switch>
            <Redirect strict from="/:college_name/" to="/:college_name" />
            <Route path="/:college_name" component={HomePageComponent} />
          </Switch>
        </Router>
      </UserProvider>
    </div>
  );
};

export default App;
