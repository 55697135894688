import React from "react";
import Heading from "./Heading";
import { Row, Col } from "antd";
const Benefit = ({ data, layout, isMobile }) => {
  const { values } = data;
  const { headerColor, gridConfig } = layout;
  return (
    <React.Fragment>
      {data.heading && (
        <Heading
          title={data.heading.title}
          description={data.heading.description}
          theme={headerColor}
        />
      )}
      <Row
        type="flex"
        justify="space-around"
        gutter={[{ xs: 24, md: 32, lg: 24 }, 20]}
        style={{ marginTop: "2rem" }}
      >
        <ul>
          {values.points.map((item, index) => (
            <Col key={index} {...gridConfig} type="flex">
              <li className="points">
                <img
                  src={require("../images/accreditions/check.svg")}
                  alt="check"
                  style={{ paddingRight: "1rem" }}
                />
                {item.title}
              </li>
            </Col>
          ))}
        </ul>
      </Row>
      <Row
        style={{
          backgroundColor: "#3c1a5b",
          padding: "2rem",
          marginTop: "3rem"
        }}
        type="flex"
      >
        {values.tag.map((item, index) => (
          <Col key={index} xs={24} md={8} lg={8} className="tags">
            <img
              src={require(`../images/accreditions/${item.iconURL}.svg`)}
              alt={item.iconUrl}
              style={{ height: "4rem" }}
            />

            <div>
              <p className="tag-title">{item.title}</p>
              <p className="tag-description">{item.description}</p>
            </div>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};
export default Benefit;
