import React from "react";
import { Row, Col } from "antd";

const Heading = ({ title, description, theme }) => {
  const headerClassName = `section-header ${
    theme === "white" ? "text-color-white" : ""
  }`;
  const paraClassName = `section-para ${
    theme === "white" ? "text-color-off-white" : ""
  }`;
  const courseHeader = "course-header text-color-white";
  return (
    <Row type="flex" justify="center">
      <Col xs={24} md={24} lg={24} type="flex" align="middle">
        <h2 className={title === "Courses" ? courseHeader : headerClassName}>
          {title}
        </h2>
        {description ? <p className={paraClassName}>{description}</p> : null}
      </Col>
    </Row>
  );
};
export default Heading;
