import React, { useEffect, useState, useContext } from "react";
import { Carousel, Row, Col, Card, Button } from "antd";
import Heading from "./Heading";
import "antd/dist/antd.css";
import axios from "axios";
import { UserContext } from "../context";
const { Meta } = Card;
const Courses = ({ data, layout, isMobile, college_id }) => {
  const { headerColor, gridConfig } = layout;
  const [courseData, setCourseData] = useState(null);
  const { setCourseList, setCourseSelected } = useContext(UserContext);

  const finalData = [];
  let itemArray = [];

  courseData &&
    courseData.forEach((item, index) => {
      itemArray.push(item);

      if ((index + 1) % 6 === 0 || index === courseData.length - 1) {
        finalData.push(itemArray);
        itemArray = [];
      }
    });
  useEffect(() => {
    try {
      let mounted = true;
      const fetchCourses = async () => {
        const courseURL =
          process.env.NODE_ENV === "production"
            ? `https://www.entrancezone.com/api/v1/microsite/${college_id}/courses`
            : `https://beta.entrancezone.com/api/v1/microsite/${college_id}/courses`;
        const res = await axios.get(courseURL);

        if (mounted) {
          setCourseData(res.data && res.data.data);
          setCourseList(res.data && res.data.data);
        }
      };

      fetchCourses();
      return () => {
        mounted = false;
      };
    } catch (error) {
      //error handling
    }
  }, []);

  const handleCourseClick = course_name => {
    window.scroll({
      top: -1000000,
      left: 0,
      behavior: "smooth"
    });
    setCourseSelected(course_name);
  };
  return (
    <React.Fragment>
      {data.heading && (
        <Heading
          title={data.heading.title}
          description={data.heading.description}
          theme={headerColor}
        />
      )}
      <Carousel>
        {finalData.map((item, index) => {
          return (
            <Row
              type="flex"
              justify="center"
              gutter={[{ xs: 24, md: 32, lg: 24 }, 20]}
              key={index}
            >
              {item.map(data => {
                return (
                  <Col
                    // {...gridConfig}
                    xs={24}
                    md={12}
                    lg={8}
                    key={data.id}
                    // type="flex"
                    className="parent-course-card"
                  >
                    <Card
                      className="course-card"
                      bordered={false}
                      onClick={() => handleCourseClick(data.short_name)}
                    >
                      <Meta
                        title={`${data.short_name}`}
                        description={`${data.full_name}`}
                      />
                      {/*<div style={{ textAlign: "left" }}>
                        <p className="course-name">{data.short_name}</p>
                        <p className="course-full-name">{data.full_name}</p>
                        
                </div>*/}
                      <p className="fee-figure">{data.first_year_fee}</p>
                    </Card>
                    <Button
                      className="apply-now-btn"
                      href="#registration"
                      block
                      onClick={() => handleCourseClick(data.short_name)}
                    >
                      Apply Now
                    </Button>
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </Carousel>
    </React.Fragment>
  );
};
export default Courses;
