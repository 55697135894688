import React, { useState } from "react";
import { Steps, Button } from "antd";
import Heading from "./Heading";
const { Step } = Steps;

const Admission = ({ data, layout, component_id }) => {
  const [currBar, setCurrBar] = useState(0);
  const { values } = data;
  const [description, setDescription] = useState(
    values[0] ? values[0].description : null
  );

  const { headerColor } = layout;
  return (
    <React.Fragment>
      {data.heading && (
        <Heading
          title={data.heading.title}
          description={data.heading.description}
          theme={headerColor}
          component_id={component_id}
        />
      )}
      <div className="stepper">
        <Steps className="progress" current={currBar}>
          {values.map((value, index) => (
            <Step
              key={index}
              onClick={() => {
                setCurrBar(index);
                setDescription(value.description);
              }}
              title={value.title}
            />
          ))}
        </Steps>
      </div>
      <div className="description-text">{description}</div>
      <div style={{ textAlign: "center" }}>
        <Button
          type="primary"
          style={{
            color: "#3c1a58",
            backgroundColor: "#faca35",
            borderColor: "#faca35",
            fontWeight: "600"
          }}
          onClick={() =>
            window.scroll({
              top: -1000000,
              left: 0,
              behavior: "smooth"
            })
          }
        >
          APPLY NOW
        </Button>
      </div>
    </React.Fragment>
  );
};

export default Admission;
