import React from "react";
import { Row, Col, Card } from "antd";

import Heading from "./Heading";
import "antd/dist/antd.css";

const Placement = ({ data, layout }) => {
  const { values } = data;
  const { headerColor } = layout;
  return (
    <React.Fragment>
      {data.heading && (
        <Heading
          title={data.heading.title}
          description={data.heading.description}
          theme={headerColor}
        />
      )}
      <Row
        className="highlight-card"
        type="flex"
        justify="center"
        gutter={[{ xs: 24, md: 32, lg: 48 }, 16]}
      >
        {values.pageOne.map((item, index) => {
          return (
            <Col {...layout.gridConfig} key={`key-${index}`}>
              <Card>
                <img
                  src={item.image_path}
                  alt={`${item.title}`}
                  style={{ objectFit: "contain" }}
                />
              </Card>
            </Col>
          );
        })}
      </Row>
    </React.Fragment>
  );
};
export default Placement;
